<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <h1 class="title  font-weight-bold display-4 text-center">Galería</h1>
        </div>
        <div class="row">
            <hr class="separator-center">
        </div>

    </div>
</div>

<div class="content-main container">
    <div class="container">
        <div class="row hoverbox ">

            <div class="col" *ngFor="let imagen of imagenes">
                <div class="centerBlock">
                    <a><img [lazyLoad]="imagen.imgPath" [src]="placeHolderImg?.imgPath" alt="description"/>
                      <img [lazyLoad]="imagen.imgPath" [src]="placeHolderImg?.imgPath" alt="description" class="preview" /></a>
                </div>
            </div>

        </div>
    </div>
</div>

