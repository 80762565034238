

<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
    </ol>

    <div class="carousel-inner">
        <div class="carousel-item active">
<!--            <img src="../../assets/inicio/bovedilla-espuma-de-poliestireno-arizona.jpg" height="800" width="1980"/>-->
            <img src="../../assets/inicio/bovedilla-espuma-de-poliestireno-arizona.jpg" />
            <div class="carousel-caption d-none d-md-block">
                <h1>Espuma de poliestireno Arizona SA de CV</h1>
                <p>Empresa 100% mexicana con más de 30 años de experiencia en el mercado de la construcción.</p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../../assets/inicio/espuma-de-poliestireno-arizona-proyectos.jpg" />
            <div class="carousel-caption d-none d-md-block">
                <h1>Vigueta y bovedilla de poliestireno</h1>
                <p>Elementos aligerantes del sistema de losas con la función de eliminar la cimbra de contacto.</p>
            </div>
        </div>
        <div class="carousel-item">
            <img src="../../assets/inicio/arizona-espuma-de-poliestireno.jpg" />
            <div class="carousel-caption d-none d-md-block">
                <h1>Productos y servicios garantizados</h1>
                <p>Contamos con personal altamente capacitado y puntualidad en la entrega de proyectos.</p>
            </div>
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
<!--<div class="container-flex phone">-->
<!--    <div class="container-flex">-->
<!--        <div class="row ">-->
<!--            <div class="col">-->
<!--                <p class="text-right"><small> 5877 6776 / 7030 1840 - De 08:00 am a 06:00 pm</small></p>-->
<!--            </div>-->
<!--            &lt;!&ndash;        <div class="col-md-4  ">&ndash;&gt;-->
<!--            &lt;!&ndash;            <p>&ndash;&gt;-->
<!--            &lt;!&ndash;                <span style="margin-right: 5px"><i class="fa fa-phone" aria-hidden="true"></i></span>&ndash;&gt;-->
<!--            &lt;!&ndash;                <small> 5877 6776 / 7030 1840 - De 08:00 am a 06:00 pm</small>&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->
<!--            &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        </div>-->
<!--    </div>-->

<!--</div>-->

<!--<div class="container-fluid" style="position: relative; top: -800px; color: white; margin: 0px">-->
<div class="container-fluid fixed-top" >
    <div class="container">
        <div class="row ">
            <div class="col">
                <p class="top-phone text-right"> <i class="bi-telephone"></i> <small> 55 5877 6776 / 55 7030 1840 - De 08:00 am a 06:00 pm</small></p>
            </div>
        </div>
    </div>

</div>
