<!--<div class="container-fluid">-->
    <form action='https://forms.zohopublic.com/mauocon/form/ContactForm/formperma/kZVMWRwYD7e907tJmqdXgsr8bJ6iHqIsacfUilsj4KI/htmlRecords/submit'
          name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data'>
        <input type="hidden" name="zf_referrer_name" value="">
        <!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
        <input type="hidden" name="zf_redirect_url" value="">
        <!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
        <input type="hidden" name="zc_gad" value="">
        <!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
        <!--    <h2>Contáctenos</h2>-->
        <p></p>

        <!--Single Line-->
        <div class="row">
            <div class="col">
                <input id="inputName" class="form-control" type="text" name="SingleLine" value="" fieldType=1
                       maxlength="255" placeholder="Nombre" required />
            </div>
        </div>
        <p></p>
        <div class="row">
            <div class="col">
                <input id="inputEmail" class="form-control" type="email" maxlength="255" name="Email" value=""
                       placeholder="Correo" fieldType=9 required/>
            </div>
        </div>
        <p></p>
        <div class="producto row">
            <div class="col">
                <input  class="form-control" type="text" maxlength="255" name="SingleLine1" value=""
                       placeholder="Producto Requerido" fieldType=1 />
            </div>
        </div>
        <p></p>
        <div class="row">
            <div class="col">
            <textarea id="inputText" class="form-control" name="MultiLine" maxlength="65535"
                      placeholder="Mensaje" required></textarea>
            </div>

        </div>
        <p></p>


        <!--    <div class="form-group">-->
        <!--        <label for="inputName">Nombre </label>-->
        <!--        <input id="inputName" class="form-control" type="text" name="SingleLine" value="" fieldType=1 maxlength="255"/>-->
        <!--    </div>-->
        <!--    <label>Nombre-->
        <!--        <em>*</em>-->
        <!--    </label>-->

        <!--Email-->
        <!--    <label>Email-->
        <!--        <em>*</em>-->
        <!--    </label>-->

        <!--    <div class="form-group">-->
        <!--        <label for="inputEmail">Email </label>-->
        <!--        -->
        <!--    </div>-->

        <!--Multi Line-->
        <!--    <label>Mensaje-->
        <!--        <em>*</em>|-->
        <!--    </label>-->
        <!--    <div class="form-group">-->
        <!--        <label for="inputText">Example textarea</label>-->

        <!--    </div>-->

        <button type="submit" class="btn btn-danger"><em>Enviar</em></button>
    </form>
<!--</div>-->

