import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: [
    '../app.component.css',
    './nosotros.component.css']
})
export class NosotrosComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
