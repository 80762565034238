<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <h1 class="font-weight-bold display-4 text-center">Contacto</h1>
        </div>
        <div class="row">
            <hr class="separator-center">
        </div>

    </div>
</div>

<div class="content-main container-fluid">
    <div class="row">
        <div class="col-12 col-sm-8 col-md-4  ">
            <div class="row">
                <div class="col"><h5>Contáctenos para una cotización rápida o llámenos</h5></div>
            </div>
            <div class="row">
                <div class="col">
                    <hr class="separator-left">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><i class="bi-envelope"></i> Correo: espumaarizona@yahoo.com.mx</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><i class="bi-telephone"></i> Oficina: 5877 6776 / 7030 184</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><i class="bi-telephone"></i> Planta: 2210 6260 / 8994 5739</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p><i class="bi-whatsapp"></i> Whatsapp: 55 6302 8449</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-contact-form></app-contact-form>
                </div>
            </div>

        </div>
        <div class="col-12 col-md-4 container title">
            <div class="row ">
                <div class="col"><h5>Dirección tienda</h5></div>
            </div>
            <div class="row">
                <div class="col">
                    <hr class="separator-left">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p>Tienda: Av. Emiliano Zapata Mza.24 Lte.35, Col. Universidad Autónoma Metropolitana, Atizapán de Zaragoza, Edo. de Méx.</p>
                </div>
            </div>
            <iframe loading="lazy" style="border: 0;"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3758.4898065588!2d-99.25122448483413!3d19.60634668678588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21c24ee8a8797%3A0xe1c99dccc668647b!2sAv%20Emiliano%20Zapata%2024%2C%20Universidad%20Aut%C3%B3noma%20Metropolitana%2C%2052919%20Cd%20L%C3%B3pez%20Mateos%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1581545794328!5m2!1ses-419!2smx"
                    width="350" height="400" frameborder="0" allowfullscreen="allowfullscreen">
            </iframe>
        </div>
        <div class="col-12 col-md-4  container title">
            <div class="row">
                <div class="col"><h5>Dirección planta</h5></div>
            </div>
            <div class="row">
                <div class="col">
                    <hr class="separator-left">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p>Planta: Carretera Principal El Vidrio-Magu Km.4, El Puerto Magu, Nicolás Romero, Estado de México.</p>
                </div>
            </div>
            <iframe loading="lazy" style="border: 0;"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3756.5078188042403!2d-99.37179618388365!3d19.690992613089993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd3e169e5d55b50e8!2s%22ESPUMA%20DE%20POLIESTIRENO%20ARIZONA%20S.A.%20DE%20C.V.%22!5e0!3m2!1ses-419!2smx!4v1583184265611!5m2!1ses-419!2smx"
                    width="350" height="400" frameborder="0" allowfullscreen="allowfullscreen">
            </iframe>
        </div>
    </div>
</div>
