import { Injectable } from '@angular/core';

@Injectable()
export class GalleryService {

    private galleryImages: GalleryImage[] = [
        { nombre: '', imgPath: 'assets/galeria/1.jpg'},
        { nombre: '', imgPath: 'assets/galeria/2.jpg'},
        { nombre: '', imgPath: 'assets/galeria/3.jpg'},
        { nombre: '', imgPath: 'assets/galeria/4.jpg'},
        { nombre: '', imgPath: 'assets/galeria/5.jpg'},
        { nombre: '', imgPath: 'assets/galeria/6.jpg'},
        { nombre: '', imgPath: 'assets/galeria/7.jpg'},
        { nombre: '', imgPath: 'assets/galeria/8.jpg'},
        { nombre: '', imgPath: 'assets/galeria/9.jpg'},
        { nombre: '', imgPath: 'assets/galeria/10.jpg'},
        { nombre: '', imgPath: 'assets/galeria/11.jpg'},
        { nombre: '', imgPath: 'assets/galeria/12.jpg'},
        { nombre: '', imgPath: 'assets/galeria/13.jpg'},
        { nombre: '', imgPath: 'assets/galeria/14.jpg'},
        { nombre: '', imgPath: 'assets/galeria/15.jpg'},
        { nombre: '', imgPath: 'assets/galeria/16.jpg'},
        { nombre: '', imgPath: 'assets/galeria/17.jpg'},
        { nombre: '', imgPath: 'assets/galeria/18.jpg'},
        { nombre: '', imgPath: 'assets/galeria/19.jpg'},
        { nombre: '', imgPath: 'assets/galeria/20.jpg'},
        { nombre: '', imgPath: 'assets/galeria/21.jpg'},
        { nombre: '', imgPath: 'assets/galeria/22.jpg'},
        { nombre: '', imgPath: 'assets/galeria/23.jpg'},
        { nombre: '', imgPath: 'assets/galeria/24.jpg'},
        { nombre: '', imgPath: 'assets/galeria/25.jpg'},
        { nombre: '', imgPath: 'assets/galeria/26.jpg'},
        { nombre: '', imgPath: 'assets/galeria/27.jpg'},
        { nombre: '', imgPath: 'assets/galeria/28.jpg'},
        { nombre: '', imgPath: 'assets/galeria/29.jpg'},
        { nombre: '', imgPath: 'assets/galeria/30.jpg'},
        { nombre: '', imgPath: 'assets/galeria/31.jpg'},
        { nombre: '', imgPath: 'assets/galeria/32.jpg'},
        { nombre: '', imgPath: 'assets/galeria/33.jpg'},
        { nombre: '', imgPath: 'assets/galeria/34.jpg'},
        { nombre: '', imgPath: 'assets/galeria/35.jpg'},
        { nombre: '', imgPath: 'assets/galeria/36.jpg'},
        { nombre: '', imgPath: 'assets/galeria/37.jpg'},
        { nombre: '', imgPath: 'assets/galeria/38.jpg'},
        { nombre: '', imgPath: 'assets/galeria/39.jpg'},
        { nombre: '', imgPath: 'assets/galeria/40.jpg'},
        { nombre: '', imgPath: 'assets/galeria/41.jpg'},
        { nombre: '', imgPath: 'assets/galeria/42.jpg'},
        { nombre: '', imgPath: 'assets/galeria/43.jpg'},
        { nombre: '', imgPath: 'assets/galeria/44.jpg'},
        { nombre: '', imgPath: 'assets/galeria/45.jpg'},
        { nombre: '', imgPath: 'assets/galeria/46.jpg'},
        { nombre: '', imgPath: 'assets/galeria/47.jpg'},
        { nombre: '', imgPath: 'assets/galeria/48.jpg'},
        { nombre: '', imgPath: 'assets/galeria/49.jpg'},
        { nombre: '', imgPath: 'assets/galeria/50.jpg'},
        { nombre: '', imgPath: 'assets/galeria/51.jpg'},
        { nombre: '', imgPath: 'assets/galeria/52.jpg'},
        { nombre: '', imgPath: 'assets/galeria/53.jpg'},
        { nombre: '', imgPath: 'assets/galeria/54.jpg'},
        { nombre: '', imgPath: 'assets/galeria/55.jpg'},
        { nombre: '', imgPath: 'assets/galeria/56.jpg'},
        { nombre: '', imgPath: 'assets/galeria/57.jpg'},
        { nombre: '', imgPath: 'assets/galeria/58.jpg'},
        { nombre: '', imgPath: 'assets/galeria/59.jpg'},
        { nombre: '', imgPath: 'assets/galeria/60.jpg'},
        { nombre: '', imgPath: 'assets/galeria/61.jpg'},
        { nombre: '', imgPath: 'assets/galeria/62.jpg'},
        { nombre: '', imgPath: 'assets/galeria/63.jpg'},
        { nombre: '', imgPath: 'assets/galeria/64.jpg'},
        { nombre: '', imgPath: 'assets/galeria/65.jpg'},
        { nombre: '', imgPath: 'assets/galeria/66.jpg'},
        { nombre: '', imgPath: 'assets/galeria/67.jpg'},
        { nombre: '', imgPath: 'assets/galeria/68.jpg'},
        { nombre: '', imgPath: 'assets/galeria/69.jpg'},
        { nombre: '', imgPath: 'assets/galeria/70.jpg'},
        { nombre: '', imgPath: 'assets/galeria/71.jpg'},
        { nombre: '', imgPath: 'assets/galeria/72.jpg'},
        { nombre: '', imgPath: 'assets/galeria/73.jpg'},
        { nombre: '', imgPath: 'assets/galeria/74.jpg'},
        { nombre: '', imgPath: 'assets/galeria/75.jpg'},
        { nombre: '', imgPath: 'assets/galeria/76.jpg'},
        { nombre: '', imgPath: 'assets/galeria/77.jpg'},
        { nombre: '', imgPath: 'assets/galeria/78.jpg'},
        { nombre: '', imgPath: 'assets/galeria/79.jpg'},
        { nombre: '', imgPath: 'assets/galeria/80.jpg'},
        { nombre: '', imgPath: 'assets/galeria/81.jpg'},
        { nombre: '', imgPath: 'assets/galeria/82.jpg'},
        { nombre: '', imgPath: 'assets/galeria/83.jpg'},
        { nombre: '', imgPath: 'assets/galeria/84.jpg'},
        { nombre: '', imgPath: 'assets/galeria/85.jpg'},
        { nombre: '', imgPath: 'assets/galeria/86.jpg'}
    ];

    private galleryPlaceHolder: GalleryImage = {nombre: '', imgPath:'assets/cropped-ARIZONA-LOGO-32x32.png'}

    constructor() {
    }

    public getImages(): GalleryImage[]
    {
        return this.galleryImages;
    }

    public getPlaceHolderImage(): GalleryImage
    {
      return this.galleryPlaceHolder;
    }

}

export interface GalleryImage{
    nombre: string;
    imgPath: string;
}
