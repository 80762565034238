
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import {ProductosComponent} from './components/productos/productos.component';
import {GaleriaComponent} from './components/galeria/galeria.component';
import {ContactoComponent} from './components/contacto/contacto.component';
import {ConfirmComponent} from './components/confirm/confirm.component';

const APP_ROUTES: Routes = [
    { path: 'inicio', component: InicioComponent },
    { path: 'nosotros', component: NosotrosComponent },
    { path: 'productos', component: ProductosComponent },
    { path: 'galeria', component: GaleriaComponent },
    { path: 'contacto', component: ContactoComponent },
    { path: 'confirm', component: ConfirmComponent },
    { path: '**', pathMatch: 'full', redirectTo: 'inicio' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { scrollPositionRestoration: 'enabled' });
