import { Component, OnInit } from '@angular/core';
import { GalleryService,  GalleryImage } from '../../services/gallery.service';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: [
    '../../app.component.css',
    './galeria.component.css']
})
export class GaleriaComponent implements OnInit {

  imagenes: GalleryImage[] = [];
  placeHolderImg?: GalleryImage;

  constructor( private galleryService: GalleryService) {

  }

  ngOnInit(): void {
    this.imagenes = this.galleryService.getImages();
    this.placeHolderImg = this.galleryService.getPlaceHolderImage();
    //console.log(this.imagenes);
  }

}
