import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowScrollService {
  positionY: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {
    document.addEventListener('scroll', () => {
      this.position();
    });
  }

  position(): void {
    this.positionY.next(window.scrollY);
  }

}
