import { Component, OnInit } from '@angular/core';
import { ProductsService, Product } from '../../services/products.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: [
    '../../app.component.css',
    './productos.component.css']
})
export class ProductosComponent implements OnInit {

  productos: Product[] = [];

  constructor( private productsService: ProductsService ) {

  }

  ngOnInit(): void {
    this.productos = this.productsService.getProductos();
  }

}
