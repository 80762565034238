<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <h1 class="title  font-weight-bold display-4 text-center">{{title}}</h1>
        </div>
        <div class="row">
            <hr class="separator-center">
        </div>
    </div>
</div>
