<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <h1 class="title  font-weight-bold display-4 text-center">Productos</h1>
        </div>
        <div class="row">
            <hr class="separator-center">
        </div>

    </div>
</div>

<div class="content-main container-fluid">
    <div class="container">
        <div class="card-columns lg-4">
            <div class="card" *ngFor="let product of productos">
                <img [src]="product.imgPath" class="card-img-top img-fluid" [alt]="product.nombre">
                <div class="card-block">
                    <h5 class="card-title">{{ product.nombre }}</h5>
                    <hr class="separator-left">
                    <p class="card-text">{{ product.texto }}</p>
                    <!--                <p class="card-text"><small class="text-muted"> Some quick example text to build on the card title and make up the bulk of the card's content.</small> </p>-->

                </div>
            </div>
        </div>
    </div>


</div>
