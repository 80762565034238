<div class="container-fluid">
    <app-header></app-header>
<!--    <app-carousel></app-carousel>-->
    <!--<div class="sticky-top bg-white hidden-spacer"> </div>-->
    <div class="container-fluid" style="padding: 0px; ">
        <router-outlet></router-outlet>
<!--        <app-inicio></app-inicio>-->
    </div>
    <app-footer></app-footer>
</div>

