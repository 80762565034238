<div class="container-fluid">
    <div class="footer contactenos row">
        <div class="col">
            <div class="row-12 text-center">
                <p> Contáctenos para obtener una cotización gratuita</p>
            </div>
            <div class="row-12 text-center">
                <button class="btn btn-danger" [routerLink]="['contacto']">Contáctenos</button>
            </div>
        </div>
    </div>
    <div class="footer contacto row">
        <div class="col-12  col-md-4">
            <div class="row">
                <h5>Acerca de la empresa</h5><br>
            </div>
            <div class="row">
                <hr class="separator-left">
            </div>
            <div class="row" style="margin-right: 10px">
                Más de 30 años con experiencia en la fabricación de vigueta de alma abierta, bovedilla de poliestireno,
                bovedilla de cemento-arena, así como productos de poliestireno, block, paneles, etc.
            </div>
            <div class="row">
                <img src="../../assets/inicio/LOGO-ARIZONA.png" width="250px"/>
            </div>
        </div>
        <div class="col-12  col-md-4">
            <div class="row">
                <h5>Comuníquese con nosotros</h5>
            </div>
            <div class="row">
                <hr class="separator-left">
            </div>
            <div class="row">
                <p><i class="bi-envelope"></i> Correo: espumaarizona@yahoo.com.mx</p>
            </div>
            <div class="row">
                <p><i class="bi-telephone"></i> Oficina: 55 5877 6776 / 55 7030 1840</p>
            </div>
            <div class="row">
                <p><i class="bi-telephone"></i> Planta: 55 2210 6260 / 55 8994 5739</p>
            </div>
            <div class="row">
                <p><i class="bi-whatsapp"></i> Whatsapp: 55 6302 8449</p>
            </div>

        </div>
        <div class="col-12  col-md-4">
            <div class="row">
                <h5>Escríbanos un mensaje</h5>
            </div>
            <div class="row">
                <hr class="separator-left">
            </div>
            <div class="row" style="padding: 0px">
                <div class="col"><app-contact-form></app-contact-form></div>
            </div>
        </div>
    </div>
</div>

