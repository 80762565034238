<nav [class.navbar-custom-clear]="isFavorite"  [class.navbar-custom-dark]="!isFavorite"
        class= "navbar navbar-custom navbar-expand-lg fixed-top navbar-light"  >
    <div class="container-fluid" style="margin-left: 50px; margin-right: 50px" >
        <a class="navbar-brand" href="#">
            <img src="../../assets/inicio/{{imgSrc}}"
                 alt="ESPUMA DE POLIESTIRENO ARIZONA SA DE CV"/>
<!--            <div class="logo"></div>-->
        </a>
<!--        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar1" >-->
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()"  >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar1" [ngClass]="{ 'show': navbarOpen }">
<!--        <div class="collapse navbar-collapse" id="navbar1" >-->
            <ul class="navbar-nav ml-auto">
<!--                active-->
                <li  class="nav-item" routerLinkActive="active">
                    <a  class="nav-link" [routerLink]="['inicio']" >Inicio</a>
                </li>
                <li  class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['nosotros']" routerLinkActive="active">Nosotros</a>
                </li>
                <li  class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['productos']">Productos</a>
                </li>
                <li  class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['galeria']">Galería</a>
                </li>
<!--                <li  class="nav-item" routerLinkActive="active">-->
<!--                    <a class="nav-link" [routerLink]="['inicio']">News</a>-->
<!--                </li>-->
                <li  class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['contacto']">Contacto</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link" href="https://www.facebook.com/poliestirenoArizona/">
                        <p><i class="bi-facebook"></i> </p>
                    </a>
                </li>
            </ul>
        </div>
    </div>


</nav>
