<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <h1 class="font-weight-bold display-4 text-center">Contacto</h1>
        </div>
        <div class="row">
            <hr class="separator-center">
        </div>

    </div>
</div>


<div class="content-main container-fluid">
<!--    <div class="row text-center">-->
        <div class="col-12  ">
            <div class="row">
                <div class="col"><h3>Su mensaje se ha enviado</h3></div>
            </div>
            <div class="row">
                <div class="col">
                    <hr class="separator-left">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>Gracias por su interés en nuestros productos.</h5>
                    <h5>Nos pondremos en contacto con usted lo antes posible.</h5>
                </div>
            </div>
        </div>

<!--    </div>-->
</div>
