import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: [
    '../app.component.css',
    './jumbotron.component.css']
})
export class JumbotronComponent implements OnInit {
  title: string;

  constructor() {
    this.title = 'placeholder';
  }

  ngOnInit(): void {
  }

}
