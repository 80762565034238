<div class="jumbotron jumbotron-fluid">
    <div class="container">
        <div class="row justify-content-center">
            <h1 class="title  font-weight-bold display-4 text-center">Nosotros</h1>
        </div>
        <div class="row">
            <hr class="separator-center">
        </div>

    </div>
</div>


<div class="content-main container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-6 text-center">
                <h3>Conozca nuestra empresa</h3>
                <hr class="separator-center">
                <h5>Más de 30 años en el mercado, fabricando y comercializando productos para el área de la construcción,
                    industria y otros.</h5>
                <p>Empresa 100 % mexicana creada en el año de 1991 siendo proveedora en proyectos importantes de la
                    construcción de casas de interés social hasta nivel residencial, edificios de departamentos verticales y
                    horizontales, Centros Comerciales, Oficinas, etc., trabajando con constructoras importantes en el
                    desarrollo de la infraestructura mexicana.</p>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <img src="../../assets/nosotros/DSC00566.jpg">
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 text-center">
                <h3>Misión</h3>
                <hr class="separator-center">
                <p>Mantenernos en el mercado bajo las normas de calidad, cuidando desde nuestras materias primas hasta el
                    producto terminado con el objetivo de satisfacer las necesidades más exigentes de nuestros clientes de
                    acuerdo con sus proyectos.</p>
            </div>
            <div class="col-12 col-sm-6 text-center">
                <h3>Visión</h3>
                <hr class="separator-center">
                <p>Ser una empresa competitiva en el mercado nacional y poder expandirnos en otras áreas relacionadas con la
                    construcción ofreciendo productos y servicio de calidad.</p>
            </div>
        </div>
    </div>

</div>


