import { Injectable } from '@angular/core';

@Injectable()
export class ProductsService {

    private productos: Product[] = [
        {
            nombre: 'Vigueta de alma abierta',
            texto: 'La vigueta de Alma Abierta es el elemento fabricado con una armadura tridimensional cumpliendo la NOM-B-456; con 3 varillas longitudinales corrugadas, una superior y dos inferiores, unidas por varillas diagonales lisas en forma de zig-zag. De acuerdo a la longitud y la capacidad de carga se adiciona acero de refuerzo  en el patín. El patín de la vigueta tiene una dimensión de 12×5 cm, es colado con un concreto F´c=200 kg/cm2.',
            imgPath: 'assets/productos/Vigueta-de-alma-abierta.jpg'
        },
        {
            nombre: 'Bovedilla de poliestireno',
            texto: 'Las bovedillas son los elementos aligerantes del sistema y pueden ser de diversos materiales como poliestireno, cemento-arena, barro, etc. Las bovedillas se apoyan directamente en las viguetas cubriendo de forma conjunta la superficie de la losa. Su función es eliminar la cimbra de contacto.',
            imgPath: 'assets/productos/Bovedilla-de-poliestireno.png'
        },
        {
            nombre: 'Bovedilla de cemento-arena',
            texto: 'Las bovedillas de Cemento-arena son de igual forma que las de poliestireno; el elemento aligerante del sistema de losas de vigueta y bovedilla, aunque esta bovedilla es mas pesada ya que es fabricada a base de tepojal y cemento. Las bovedillas se apoyan directamente en las viguetas cubriendo de forma conjunta la superficie de la losa. Su función es eliminar la cimbra de contacto.',
            imgPath: 'assets/productos/Bovedilla-de-cemento-arena.png'
        },
        {
            nombre: 'Casetón de poliestireno',
            texto: 'El casetón se utiliza en  losa reticular; es un proceso constructivo para la construcción de losas de entrepisos y azoteas, en las que se optimiza el uso de acero y concreto al integrarse el casetón en la sección de losa que no está sujeta a ningún esfuerzo mecánico. Por su ligereza, representa una considerable disminución de peso total de la estructura, lo que significa una reducción de costos desde la cimentación.',
            imgPath: 'assets/productos/Casetn-de-poliestireno.jpg'
        },
        {
            nombre: 'Panel para muro Arizona',
            texto: 'Elemento formado por dos mallas electrosoldadas de calibre 14 con retículas de 5 x 5 cm y un núcleo con placa de poliestireno, unida con conectores de alambre de acero formando una estructura tridimensional. Este elemento por su diseño es liviano y se puede transportar, manejar e instalar fácil y rápido.',
            imgPath: 'assets/productos/Panel-para-muro-Arizona.jpg'
        },
        {
            nombre: 'Placa de poliestireno',
            texto: 'Se utiliza principalmente para la construcción en elementos no estructurales; en junta de edificaciones, relleno para reducir el peso de una losa en desnivel, para falsos plafones, empaques,  así como para aislamientos térmicos y acústicos.',
            imgPath: 'assets/productos/Placa-de-poliestireno.jpg'
        },
        {
            nombre: 'Panel para losa Arizona',
            texto: 'El panel para losa es fabricado de igual manera que el panel de muro; formado por dos mallas electrosoldadas, un núcleo de poliestireno y conectores que unen las mallas. Solo que este lleva en la placa de poliestireno nervaduras en las cuales se alojara el acero de refuerzo para soportar la capacidad de  carga requerida.',
            imgPath: 'assets/productos/Panel-para-losa.jpg'
        },
        {
            nombre: 'Molduras y Capiteles de poliestireno',
            texto: 'Elemento decorativo formado por piezas de poliestireno de alta densidad las cuales pueden ser utilizadas en exteriores para dar forma al colado de una losa o columna y en interiores para decorar. Hay una gran gama de modelos y también se pueden fabricar según diseño.',
            imgPath: 'assets/productos/Molduras-y-Capiteles-de-poliestireno.jpg'
        },
        {
            nombre: 'Block macizo, hueco, de figura y color',
            texto: 'Elemento vibro comprimido fabricado a base de materiales ligeros con dimensiones normalizadas y estandarizadas, así mismo cumpliendo con la resistencia a la compresión según las normas. Contamos con block macizo ligero, block  hueco, block con pigmento y figura.',
            imgPath: 'assets/productos/Block-macizo-block-hueco-block-de-figura-y-color.jpg'
        }
    ];

    constructor() {
    }

    public getProductos(): Product[]
    {
        return this.productos;
    }

}

export interface Product{
    nombre: string;
    texto: string;
    imgPath: string;
}
