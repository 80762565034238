import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// Rutas
import { APP_ROUTING } from './app.routes';

// Servicios
import { ProductsService } from './services/products.service';
import {GalleryService} from './services/gallery.service';

// Componentes
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { CarouselComponent } from './carousel/carousel.component';
import { InicioComponent } from './inicio/inicio.component';
import { FooterComponent } from './footer/footer.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { ProductosComponent } from './components/productos/productos.component';
import { GaleriaComponent } from './components/galeria/galeria.component';

import { ContactoComponent } from './components/contacto/contacto.component';
import { ConfirmComponent } from './components/confirm/confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CarouselComponent,
    InicioComponent,
    FooterComponent,
    ContactFormComponent,
    NosotrosComponent,
    JumbotronComponent,
    ProductosComponent,
    GaleriaComponent,
    ContactoComponent,
    ConfirmComponent
  ],
  imports: [
    BrowserModule,
    LazyLoadImageModule,
      APP_ROUTING
  ],
  providers: [
      ProductsService,
      GalleryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
