import {Component} from '@angular/core';
import {WindowScrollService} from '../services/window-scroll.service';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {
    isFavorite = true;
    imgSrc = '';
    navbarOpen: any;


    constructor(private scrollService: WindowScrollService, router: Router ) {
        scrollService.positionY.subscribe(
            (res) => {

                // console.log('posy: ', res);
                if (res > 100) {
                    this.isFavorite = false;
                    this.imgSrc = 'LOGO-ARIZONA-B.png';
                } else {
                    this.isFavorite = true;
                    this.imgSrc = 'LOGO-ARIZONA.png';
                }
            }
        );

        router.events.subscribe((event: Event) => {

            if (event instanceof NavigationStart) {
                // Show loading indicator
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                // console.log('end');
                 this.navbarOpen = false;
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                //console.log(event.error);
            }
        });

    }

    onActivate($event: any) {
        window.scroll(0, 0);
    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
        //console.log('toggle');
    }
}
