<app-carousel></app-carousel>

<div class="content-main container-fluid">
    <div class="container-fluid">
        <div class="container-fluid inicio">
            <div class="row ">
                <div class="col-lg-4">
                    <div class="card lg-4" style="max-width: 540px;">
                        <div class="row no-gutters">
                            <div class="col-lg-2">
                                <img src="../../assets/inicio/vigueta-alma-abierta-3.png" height="52" width="52"/>
                            </div>
                            <div class="col-lg-10">
                                <div class="card-body">
                                    <h5 class="card-title">Vigueta de alma abierta</h5>
                                    <hr class="separator-left">

                                    <p class="card-text">Elemento fabricado con una armadura tridimensional
                                        cumpliendo la NOM-B-456; con 3 varillas longitudinales corrugadas,
                                        una superior y dos inferiores, unidas por varillas diagonales lisas en
                                        forma de zig-zag.</p>
                                    <!--                                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card col-lg-4" style="max-width: 540px;">
                        <div class="row no-gutters">
                            <div class="col-lg-2">
                                <img src="../../assets/inicio/Bovedilla-de-poliestireno-icon.png" height="52" width="52"/>
                            </div>
                            <div class="col-lg-10">
                                <div class="card-body">
                                    <h5 class="card-title">Bovedilla de poliestireno</h5>
                                    <hr class="separator-left">

                                    <p class="card-text">Aligerantes del sistema que se apoyan directamente en las viguetas
                                        cubriendo de forma conjunta la superficie de la losa.</p>
                                    <!--                                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card col-lg-4" style="max-width: 540px;">
                        <div class="row no-gutters">
                            <div class="col-lg-2">
                                <img src="../../assets/inicio/Bovedilla-de-cemento-y-arena-icon.png" height="64"
                                     width="64"/>
                            </div>
                            <div class="col-lg-10">
                                <div class="card-body">
                                    <h5 class="card-title">Bovedilla de cemento-arena</h5>
                                    <hr class="separator-left">
                                    <p class="card-text">Elemento que sustituye la bovedilla de poliestireno, es fabricada a
                                        base de tepojal y cemento.</p>
                                    <!--                                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid empresa">
        <div class="container-fluid inicio">
            <div class="row ">
                <div class="col-12 col-md-6">
                    <h2 class="text-center ">Conozca nuestra empresa</h2>
                    <hr class="separator-center">
                    <div class="separator"></div>
                    <h4 class="text-center">Más de 30 años en el mercado, fabricando y comercializando productos para el
                        área de la
                        construcción</h4>
                    <div class="separator"></div>
                    <p class="text-center "> Hemos logrado la aceptación de los clientes gracias al cumplimiento de los
                        estándares de calidad y
                        de
                        servicio, además de brindar fuentes de empleo a personas comprometidas con su trabajo. </p>

                    <div class="row justify-content-center">
                        <button class="btn btn-danger"  routerLink="/nosotros">Leer más</button>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="row justify-content-center">
                        <div class="separator"></div>
                        <img src="../../assets/inicio/espuma-arizona-empresa.jpg"/>
                        </div>

                </div>
            </div>
        </div>
    </div>

    <div class="productos container-fluid">
        <!--    <h5>Hola mundo</h5>-->
        <div class="row justify-content-center">
            <!--        <div class="col-5">-->
            <!--        </div>-->
            <!--        <div class="col-2" align="center">-->
            <!--            <h2>Productos</h2>-->
            <!--            <hr class="separator-center">-->
            <!--        </div>-->
            <!--        <div class="col-5">-->
            <!--        </div>-->
            <h2>Productos</h2>
        </div>
        <div class="row justify-content-center">
            <hr class="separator-center">
        </div>

        <div class="row " style="margin-top: 30px">
            <div class="col col-12 col-lg-2">
                <div class="row justify-content-center">
                    <img class="product-img" src="../../assets/inicio/Vigueta-alma-abierta-2.png"/>
                </div>
                <div class="row justify-content-center">
                    <h5>Vigueta alma abierta</h5>
                </div>
            </div>

            <div class="col col-12 col-lg-2">
                <div class="row justify-content-center">
                    <img class="product-img" src="../../assets/inicio/Bovedilla-de-poliestireno-1-2.png"/>
                </div>
                <div class="row justify-content-center">
                    <h5>Bovedilla de poliestireno</h5>
                </div>
            </div>

            <div class="col col-12 col-lg-2">
                <div class="row justify-content-center">
                    <img class="product-img" src="../../assets/inicio/Bovedilla-de-cemento-y-arena-2.png"/>
                </div>
                <div class="row justify-content-center">
                    <h5>Bovedilla de cemento-arena</h5>
                </div>
            </div>

            <div class="col col-12 col-lg-2">
                <div class="row justify-content-center">
                    <img class="product-img" src="../../assets/inicio/Cortes-especiales-2.png"/>
                </div>
                <div class="row justify-content-center">
                    <h5>Cortes especiales</h5>
                </div>
            </div>

            <div class="col col-12 col-lg-2">
                <div class="row justify-content-center">
                    <img class="product-img" src="../../assets/inicio/Caseton-de-poliestireno-2.png"/>
                </div>
                <div class="row justify-content-center">
                    <h5>Caseton de poliestireno         </h5>
                </div>
            </div>

            <div class="col col-12 col-lg-2">
                <div class="row justify-content-center">
                    <img class="product-img" src="../../assets/inicio/Block-hueco-1.png"/>
                </div>
                <div class="row justify-content-center">
                    <h5>Block hueco</h5>
                </div>
            </div>

        </div>
        <div class="row" style="height: 30px"></div>
        <div class="row justify-content-center">
<!--            <div class="col-2" align="center ">-->
                <button class="btn btn-danger" routerLink="/productos" >Ver Productos</button>

<!--            </div>-->
        </div>
    </div>
</div>



