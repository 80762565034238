import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: [
      '../app.component.css',
      './inicio.component.css'// Predomina el segundo
      ]
})
export class InicioComponent implements OnInit {

    constructor() {  }

  ngOnInit(): void {
  }

}
